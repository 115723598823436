@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Avenir.eot'); /* IE9 Compat Modes */
  src: local('Avenir'), local('Avenir'),
       url('./assets/fonts/Avenir.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Avenir.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Avenir.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Avenir.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/Avenir.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: bold;
  src: url('./assets/fonts/Avenir-Black.eot'); /* IE9 Compat Modes */
  src: local('Avenir'), local('Avenir'),
       url('./assets/fonts/Avenir-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Avenir-Black.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Avenir-Black.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Avenir-Black.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/Avenir-Black.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Avenir';
  font-style: oblique;
  font-weight: bold;
  src: url('./assets/fonts/Avenir-BlackOblique.eot'); /* IE9 Compat Modes */
  src: local('Avenir'), local('Avenir'),
       url('./assets/fonts/Avenir-BlackOblique.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Avenir-BlackOblique.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Avenir-BlackOblique.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Avenir-BlackOblique.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/Avenir-BlackOblique.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Avenir-Book.eot'); /* IE9 Compat Modes */
  src: local('Avenir'), local('Avenir'),
       url('./assets/fonts/Avenir-Book.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Avenir-Book.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Avenir-Book.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Avenir-Book.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/Avenir-Book.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Avenir-BookOblique.eot'); /* IE9 Compat Modes */
  src: local('Avenir'), local('Avenir'),
       url('./assets/fonts/Avenir-BookOblique.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Avenir-BookOblique.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Avenir-BookOblique.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Avenir-BookOblique.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/Avenir-BookOblique.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/Avenir-Heavy.eot'); /* IE9 Compat Modes */
  src: local('Avenir'), local('Avenir'),
       url('./assets/fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Avenir-Heavy.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Avenir-Heavy.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Avenir-Heavy.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/Avenir-Heavy.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Avenir-HeavyOblique.eot'); /* IE9 Compat Modes */
  src: local('Avenir'), local('Avenir'),
       url('./assets/fonts/Avenir-HeavyOblique.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Avenir-HeavyOblique.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Avenir-HeavyOblique.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Avenir-HeavyOblique.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/Avenir-HeavyOblique.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Avenir-Light.eot'); /* IE9 Compat Modes */
  src: local('Avenir'), local('Avenir'),
       url('./assets/fonts/Avenir-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Avenir-Light.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Avenir-Light.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Avenir-Light.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/Avenir-Light.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Avenir-LightOblique.eot'); /* IE9 Compat Modes */
  src: local('Avenir'), local('Avenir'),
       url('./assets/fonts/Avenir-LightOblique.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Avenir-LightOblique.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Avenir-LightOblique.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Avenir-LightOblique.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/Avenir-LightOblique.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Avenir-Medium.eot'); /* IE9 Compat Modes */
  src: local('Avenir'), local('Avenir'),
       url('./assets/fonts/Avenir-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Avenir-Medium.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Avenir-Medium.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Avenir-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/Avenir-Medium.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Avenir-MediumOblique.eot'); /* IE9 Compat Modes */
  src: local('Avenir'), local('Avenir'),
       url('./assets/fonts/Avenir-MediumOblique.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Avenir-MediumOblique.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Avenir-MediumOblique.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Avenir-MediumOblique.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/Avenir-MediumOblique.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Avenir-Oblique.eot'); /* IE9 Compat Modes */
  src: local('Avenir'), local('Avenir'),
       url('./assets/fonts/Avenir-Oblique.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Avenir-Oblique.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Avenir-Oblique.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Avenir-Oblique.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/Avenir-Oblique.svg#OpenSans') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Avenir-Roman.eot'); /* IE9 Compat Modes */
  src: local('Avenir'), local('Avenir'),
       url('./assets/fonts/Avenir-Roman.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Avenir-Roman.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Avenir-Roman.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Avenir-Roman.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/Avenir-Roman.svg#OpenSans') format('svg'); /* Legacy iOS */
}






html, body, #root {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex: 1;
}

body {
  font-family: 'Avenir';
  color: #717171;
  font-size: calc(14px + 1vmin);
  background-color: #f6efea;
}

h1,h2,h3,h4,h5,h6 {
  color:#2d4a1a;
  font-weight: 900;
}

a, a:hover, a:active {
  color: #717171;
  text-decoration: none;
}

strong {
  font-weight: 900;
}

label {
  font-weight: 900;
  color: #80AE26;
}