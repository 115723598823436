.cardRodada {
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}
.cardRodada .card-body {
  padding: 0;
  text-align: center;
}
.cardRodada .card-title {
  font-size: xx-large;
  padding: 1.25rem;
  background-color: #cecece;
  margin: 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.cardRodada .card-text {
  display: flex;
  padding: 0.25rem;
}
.cardRodada .card-text > div {
  flex: 1;
}

.form-check-inline {
  margin-left: .75rem;
}
@media (max-width: 767px) {
  .cardRodada{
    flex: 1;
  }
  .cardRodada .card-body {
    flex-direction: row;
    display: flex;
  }
  .cardRodada .card-title {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
    flex: 2;
  }
  .cardRodada .card-text {
    flex-direction: column;
    flex: 1;
    padding: 1.25rem;
  }

  .cardRodada .card-text > div {
    font-size: 0.7rem;
  }
}
.filtroMeusPedidos button {
  width: 100%;
  margin: 1rem 0;
}
/* Header Navbar
-----------------------------------------------------------------------*/
.navbar.navbar-dark {
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 56px;
  top: 0;
  background-color: #f6efea;
}
.navbar-toggler {
  padding: .25rem;
}
.navbar-dark .navbar-toggler {
  color: transparent;
  border-color: transparent;
  position: relative;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(142, 172, 56, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .cart-icon {
  background-image: url("./assets/svg/cart.svg");
}
.navbar-dark .num-cart {
  position: absolute;
  min-width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #80AE26;
  font-size: 0.6rem;
  line-height: 0.5rem;
  color: #fff;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  top: -2px;
  right: -3px;
}
.navbar-dark .back-icon {
  background-image: url("./assets/svg/back.svg");
}
.cart-icon {
  display: inline-block;
  width: 1em;
  height: 0.9em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
.back-icon {
  display: inline-block;
  width: 1em;
  height: 0.9em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
.search-icon {
  background-image: url("./assets/svg/search.svg");
  display: inline-block;
  width: 1em;
  height: 0.9em;
  vertical-align: middle;
  content: "";
  background-size: 100% 100%;
}
.search-form {
  margin: 1rem auto;
}
.search-form .form-control {
  min-height: 1rem;
  border-top-right-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
}
.search-form .btn.btn-outline-secondary {
  background-color: #fff;
  border: none;
  border-top-left-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem;
}
.App {
  min-height: 100%;
  flex-direction: column;
  display: flex;
  flex: 1;
}
.Content {
  padding: 1.2rem;
}
.Header {
  min-height: 200px;
  display: flex;
  align-items: center;
  border-radius: 0 0 20px 20px;
  margin-bottom: 1rem;
}
.Header.bgGrey {
  margin-bottom: 1rem;
}
.contentHeader {
  padding: 2rem 2rem;
}
.title {
  color: #80AE26;
}
.colorWhite {
  color: #fff;
}
.contentButton {
  padding: 1.5rem
}
.contentPages {
  padding-top: 56px;
}
.contentButton .btn {
  border-width: 2px;
  border-radius: 25px;
  line-height: 25px;
  min-width: 150px;
}
.boxLineGrey, .boxLineGreen, .boxLineRed {
  margin-bottom: 0.657rem;
}
.boxLineGrey.link {
  display: flex;
}
.boxLineGrey {
  flex: 1;
  border-radius: 5px;	
  padding: 0.65rem 0.65rem;
  background-color: #EDE7E4;;
}
.boxLineGreen, .boxLineRed {
  flex: 1;
  /*height: 167.5px;	*/
  border-radius: 5px;	
  font-size: smaller;
  /*padding: 1.9rem;*/
  color: #fff;
}
.boxLineGreen, .bgGreen {
  background-color: #80AE26;
}
.boxLineRed, .bgRed {
  background-color: #AD3434;
  text-align: center;
}
.contentMeusPedidos {
  padding: 3rem 2rem;
  align-items: center;
}
.contentMeusPedidos img {
  width: 4rem;
  height: 4rem;
}
.bgGrey {
  background-color: #F0E8E5;
}
.bgRed span, 
.boxLineRed span {
  color:#FFC000;
  font-weight: 900;
}
.alignCenter h4 {
  line-height: 1.5rem;
  margin: 0;
}
.alignCenter .media {
  align-items: center;
}
.alignCenter .row {
  align-items: center;
}
.alignCenter .form-group {
  margin: 0;
}
.alignCenter .form-control {
  font-family: 'Avenir';
  font-size: calc(14px + 1vmin);
  color: #000;
  background-color: transparent;
  font-weight: 900;
  padding: 0 0.8rem 0 0;
}
@media (max-width: 1280px) {
  .alignCenter .form-control {
    background-image: url("./assets/svg/Rectangle.svg");
    background-size: 0.8rem;
    background-repeat: no-repeat;
    background-position: right center;
  }
}
.alignCenter strong {
  color: #80AE26;
}
.alignCenter .itemValor {
  text-align: right;
}
.alingFilter {
  text-align: right;
}
.alingFilter .btn.btn-filter {
  color: #80AE26;
}
.contentCenter {
  text-align: center;
  align-items: center;
  padding-top: 1.5rem;
}
.contentCenter .total {
  font-size: 1.5rem;
  text-align: center;
}
.contentCenter .valor {
  font-size: 1.5rem;
  text-align: left;
  color: #80AE26;
}
.btn.btn-custom {
  background-color: #80AE26;
  line-height: 2.5rem;
  border-radius: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.borderRadius-1{ border-radius: 1px; }
.borderRadius-2{ border-radius: 2px; }
.borderRadius-3{ border-radius: 3px; }
.borderRadius-4{ border-radius: 4px; }
.borderRadius-5{ border-radius: 5px; }
.borderRadius-5{ border-radius: 6px; }
.borderRadius-7{ border-radius: 7px; }
.borderRadius-8{ border-radius: 8px; }
.borderRadius-9{ border-radius: 9px; }
.borderRadius-10{ border-radius: 10px; }
.borderRadius-11{ border-radius: 11px; }
.borderRadius-12{ border-radius: 12px; }
.borderRadius-13{ border-radius: 13px; }
.borderRadius-14{ border-radius: 14px; }
.borderRadius-15{ border-radius: 15px; }
.borderRadius-16{ border-radius: 16px; }
.borderRadius-17{ border-radius: 17px; }
.borderRadius-18{ border-radius: 18px; }
.borderRadius-19{ border-radius: 19px; }
.borderRadius-20{ border-radius: 20px; }
.borderCicle{ border-radius: 50%; }

/* BOOTSTRAP AJUSTES
------------------------------------------------------------------------*/
/* TABS Inicio */
.nav-item.nav-link {
  border-radius: 0;
  font-size: 1.5rem;
  font-weight: 900;
  border-bottom: 3px solid transparent;
}
.nav-item.nav-link.active {
  color: #80AE26;
  background-color: transparent;
  border: none;
  border-bottom: 3px solid #80AE26;
}
.nav-tabs {
  border-bottom: none;
}
.tab-content {
  padding-top: 1rem;
}
/* TABS Final */
.nav-link {
  padding-bottom: 0;
  margin-bottom: 1rem;
}
.nav-pills .nav-link {
  border-radius: 0;
  font-size: 1.5rem;
  font-weight: 900;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #80AE26;
  background-color: transparent;
  border-bottom: 3px solid #80AE26;
}
.form-control {
  border: none;
  min-height: 3.5rem;
  line-height: 3.5rem;
}
.form-check-label {
  color: #717171;
  font-weight: 500;
}
input[type=checkbox] {
  background-color: #fff;
}
.mainMenuList .borderCicle {
  width: 3rem;
  height: 3rem;
  padding: 0.85rem;
  line-height: 1rem;
  text-align: center;
  background-color: #80AE26;
}
.iconSticker,
.iconClose {
  fill: #fff;
}
.mainMenuList .media-body {
  line-height: 3rem;
}


.mainMenu {
  position: fixed;
  width: 100%;
  margin-left: -100%;
  padding: 1.8rem  1rem;
  z-index: 4;
  top: 0;
  bottom: 0;
  background-color: #fff;
  transition-timing-function: linear;
  transition: margin-left 0.5s;
  background-image: url("./assets/images/folhaLeft.png"), url("./assets/images/folhaRight.png");
  background-position: top left, top right;
  background-repeat: no-repeat;
}
.mainMenu.active {
  margin-left: 0;
}

.mainMenuList {
  padding: 0
}
.mainMenuList > li {
  padding: 1rem
}
.btnClose {
  margin-left: 1rem;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  background-color: #80AE26;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  line-height: 0.9rem;
  padding: 1.4rem;
}

.iconNext {
  font-size: 1.4rem;
  line-height: 2.8rem;
  margin-right: 0 !important;
}
.iconDownload {
  font-size: 1.8rem;
}

.contentEndereco {
  margin-top: 2rem;
}
.pagePedidoCompleto {
  background-image: url("./assets/images/folhaLeft.png"), url("./assets/images/folhaRight.png");
  background-position: top left, top right;
  background-repeat: no-repeat;
}
.pedidoComplete {
  /* padding-top: 50%; */
  text-align: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}


/* SLIDE CUSTOM
-------------------------------------------------------------------------------*/
input[type=range].range-slider.range-slider--custon::-webkit-slider-thumb {
  background: linear-gradient(180deg, #B7D74D 0%, #80AE26 100%);
}
input[type=range].range-slider.range-slider--custon::-moz-range-thumb {
  background: linear-gradient(180deg, #B7D74D 0%, #80AE26 100%);
}
input[type=range].range-slider.range-slider--custon::-ms-thumb {
  background: linear-gradient(180deg, #B7D74D 0%, #80AE26 100%);
}

input[type=range].range-slider.range-slider--lg::-webkit-slider-thumb {
  height: 2.8rem;
  width: 2.8rem;
  margin-top: -1.2rem;
}
input[type=range].range-slider.range-slider--lg::-moz-range-thumb {
  height: 2.8rem;
  width: 2.8rem;
}
input[type=range].range-slider.range-slider--lg::-ms-thumb {
  height: 2.8rem;
  width: 2.8rem;
  margin-top: 0;
}

/* Home Hodada
------------------------------------------------------------------------------------*/

.itemCategoria .imageCategoria {
  border-radius: 0.5rem;
  text-align: center;
  padding: 0px 10px;
  min-height: 3.4rem;
}
.itemCategoria .imageCategoria img {
  width: 100%;
  height: 100%;
}
.itemCategoria span {
  font-size: 0.9rem;
}
.contentCategorias .col-3,
.contentProdutoCard .col-6 {
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.contentProdutoCard .card-body {
  padding: 1.188rem;;
}
.contentProdutoCard .card-title {
  font-size: 0.9rem;
  margin: 0;
}
.contentProdutoCard .card-img-top {
  height: 7rem;
}

@media (min-width: 992px) {
  .itemCategoria .imageCategoria img {
    width: 40%;
  }
  .contentProdutoCard .card-img-top {
    height: 12rem;
  }
  .contentProdutoCard .card-title {
    font-size: 1.2rem;
  }
  .contentProdutoCard .card-text {
    font-size: 1.9rem;
  }
}
@media (min-width: 576px) {
  .contentProdutoCard .card-img-top {
    height: 10rem;
  }
}

@media (min-width: 768px) {
  .contentProdutoCard .card-img-top {
    height: 13rem;
  }
}
@media (min-width: 992px) {
  .contentProdutoCard .card-img-top {
    height: 10rem;
  }
}

@media (min-width: 1400px) {
  .contentProdutoCard .card-img-top {
    height: 17rem;
  }
}

/* Pagina Produto
-------------------------------------------------------------------------------------*/
.pageProduto {
  background-size: cover;
  flex: 1;
  position: relative;
}
.pageProduto .contentProduto {
  padding: 1rem
}
.pageProduto .contentProdutoInfo {
  background-color: #fff;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 50%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 1.5rem;
}
@media (min-width: 992px){
  .pageProduto .contentProdutoInfo {
    width: 40rem;
    height: 25rem;
    left: 50%;
    margin-left: -20rem;
    margin-top: 12.5rem;
    bottom: auto;
    right: auto;
    border-radius: 1rem;
  }
}
.pageProduto .contentProdutoInfo h4 {
  margin-bottom: 0;
}
.pageProduto .contentProdutoInfo .precoProduto {
  color: #80AE26;
  margin-bottom: 2rem;
}

.pageProduto .contentProdutoInfo h6 {
  font-weight: bold;
}
.pageProduto .description {
  line-height: 1rem;
  font-size: 75%;
  margin-bottom: 2rem;
}
.pageProduto .contemPesoValor {
  flex-direction: row;
  display: flex;
  padding-top: 0.5rem;
  align-items: center;
}
.pageProduto .contemPesoValor .contemPeso,
.pageProduto .contemPesoValor .contemValor {
  flex: 1;
}
.pageProduto .contemPesoValor .contemPeso {
  font-weight: 900;
  color: #000;
}
.pageProduto .contemPesoValor .contemValor {
  text-align: right;
  color: #80AE26;
  font-weight: 900;
  font-size: 1.4rem;
}
.pageProduto .contemFinalizaPedido a,
.pageProduto .contemFinalizaPedido button {
  font-size: 0.6rem;
  flex: 1;
  line-height: 2rem;
  border-radius: 1.5rem;
  background-color: #80AE26;
  border-color: #80AE26;
}
.pageProduto .contemFinalizaPedido button {
  flex: 2;
  margin-right: 0.5rem;
}
.pageProduto .contemFinalizaPedido{
  display: flex;
}
.pageProduto a:first-child {
  flex: 2;
  margin-right: 0.5rem;
}

.pageProduto .tagView {
  /*position: absolute;
  top: -3rem;*/
}
.pageProduto .tagView span {
  background-color: #fff;
  border-radius: 1rem;
  padding: 0.375rem 1rem;
  font-size: 0.8rem;
  color: #AD3434;
}

/* LOGIN
------------------------------------------------------------------------*/
.login-form {
    flex: 1 1 0%;
    display: flex;
    margin: 0px;
    align-items: center;
    justify-content: center;
}
.login-form::after {
  /* background-image: url('https://www.aintec.com.br/wp-content/uploads/2018/10/Future-of-Agriculture-feature.jpg');
  background-size: cover;
  background-position: center center; */
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.6;
}
.login-form form {
  width: 80%;
}
.login-form button,
.login-form a {
  background-color: #80AE26;
  border-color: #80AE26;
  border-radius: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
}
.login-form .btnCenter {
  text-align: center;
}
.login-form label {
  color: #2d4a1a;
}
@media (min-width: 576px) {
  .login-form form {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .login-form form {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .login-form form {
    width: 40%;
  }
}

@media (min-width: 1400px) {
  .login-form form {
    width: 30%;
  }
}